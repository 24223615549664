<template>
  <div v-if="model != false && roles.length === 3">
    <b-row class="mb-5">
      <b-col offset-md="4" md="4">
        <b-input-group>
          <b-form-input v-model="search" placeholder="Key"></b-form-input>
          <b-input-group-append>
            <b-button variant="success" class="btn-icon" @click="save()">
              <i v-if="save_loader === false" class="fas fa-save"></i>
              <i v-else class="fas fa-spinner fa-spin"></i>
            </b-button>
            <b-button variant="primary" class="btn-icon" @click="add()"><i class="fas fa-plus"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" v-for="(f,i) in fields" :key="i" class="mb-3">
        <b-list-group class="text-center">
          <b-list-group-item active>
            <b-input-group>
              <b-form-input v-model="f.label" placeholder="Label"></b-form-input>
              <b-input-group-append>
                <b-button variant="danger" class="btn-icon" @click="remove(i)"><i class="fas fa-trash"></i></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-input v-model="f.key" placeholder="Key"></b-form-input>
          </b-list-group-item>
          <b-list-group-item>
            <select class="form-control" v-model="f.type">
              <option v-for="t in type" :value="t" :key="t">{{t}}</option>
            </select>
          </b-list-group-item>
          <b-list-group-item>
            <Input v-if="f.type === 'select-model'" v-model="f.model" type="select-model" :options="params" :multiple="false"/>
            <b-form-input v-else value="Non utile" disabled></b-form-input>
          </b-list-group-item>
          <b-list-group-item>
            <span>Lecture</span>
            <b-form-checkbox-group v-model="f.read" :options="roles"></b-form-checkbox-group>
          </b-list-group-item>
          <b-list-group-item>
            <span>Ecriture</span>
            <b-form-checkbox-group v-model="f.edit" :options="roles"></b-form-checkbox-group>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-input type="number" v-model="f.md" placeholder="MD" min="1" max="12"></b-form-input>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-input type="number" v-model="f.sm" placeholder="SM" min="1" max="12"></b-form-input>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox v-model="f.required">Obligatoire</b-form-checkbox>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Input from '@/components/InputForm';
export default {
  name: "MagicListe",
  components: {
    Input
  },
  data(){
    return {
      search:null,
      save_loader: false,
      model:false,
      type:['number', 'text', 'textarea', 'date', 'select-model', 'checkbox', 'price', 'array'],
      params:[],
      roles:[],
      tmp:{
        "label": null,
        "key": "new",
        "type": null,
        "model": null,
        "read": [],
        "edit": [],
        "md": null,
        "sm": null,
        "required": false
      },
    }
  },
  computed:{
    fields(){
      if(this.search === null || this.search === ''){
        return this.model.fields;
      }
      return this.model.fields.filter(f => f.key.indexOf(this.search) > -1);
    }
  },
  watch:{
  },
  beforeMount() {
    for (const [key] of Object.entries(this.$api.params)) {
      this.params.push({
        id:key,
        titre:key
      })
    }
    this.$api.params.UserRole.forEach((r) => {
      this.roles.push({
        text: r.titre,
        value: r.id
      })
    });
  },
  mounted(){
    this.$api.ajax('/param/model/'+this.$route.params.name, null, res => {
      if(res.status === true){
        this.model = res.data
      }
    })
  },
  methods: {
    save(){
      if(this.save_loader === true){
        return false;
      }
      this.save_loader = true;
      this.$api.ajax('/param/model', this.model, res => {
        if(res.status === true){
          this.mode = res.data
        }
        this.save_loader = false;
      })
    },
    add(){
      this.model.fields.push({
        "label": null,
        "key": "new",
        "type": null,
        "model": null,
        "read": [],
        "edit": [],
        "md": null,
        "sm": null,
        "required": false
      });
    },
    remove(i){
      this.model.fields.splice(i,1);
    }
  }
}
</script>
<style>
table {
  table-layout: fixed !important;
}
</style>
